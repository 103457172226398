import * as React from 'react';
import Logo from "../../app/components/logo.png"
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton, ImageList, ImageListItem, Grid, Card, CardContent, CardHeader } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput, sec_button, ter_button } from '../../app/components/Styles';
import { Image, notification, Progress } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { getEvent, joinEvent, uploadFiles } from '../../app/store/actions/guestActions';
import { isMobile } from 'react-device-detect';
import { Album, Book, Collections, Delete, LibraryMusic, MenuBook, Mic, Notifications, Photo, Upload, Videocam } from '@mui/icons-material';
import { useState } from 'react';
import ReactFileReader from "react-file-reader";
import { capitalize } from 'lodash';
import { v4 as uuid } from 'uuid';
import { config } from '../../app/config';
import { uploadFile } from '../../core/aws';
import { AudioRecorder } from 'react-audio-voice-recorder';
import RecordAudioCard from '../../app/components/RecordAudioCard';
import { getRequest } from '../../core/network';
import axios from 'axios';

export const RecordAudio = ({ user, uploadFiles, isLoading, event, getEvent, token }) => {

    const { eventId } = useParams()
    const [audio, setAudio] = useState([]);
    const [media, setMedia] = useState([]);
    const [fileTypes, setFileTypes] = useState(["audio/*"]);
    const [fetchingFile, setFetchingFile] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        getEvent({ eventId })
    }, [eventId]);

    const handleAddFiles = (files) => {
        console.log(files);
        setFetchingFile(true);
        let oldMedia = [...media]
        let newMedia = [...files]
        newMedia = newMedia.filter((file) => oldMedia.find((oldFile) => oldFile.name === file.name) === undefined)
        oldMedia.push(newMedia);
        newMedia = oldMedia.flat();
        setMedia(newMedia);
        setFetchingFile(false);
    };

    const handleRemoveFile = (fileName) => {
        const newMedia = [...media].filter((file) => file.name !== fileName)
        setMedia(newMedia);
    };

    const handleSubmit = async (ev) => {
        ev.preventDefault();
        const formData = new FormData(ev.currentTarget);
        try {
            const files = [];
            setUploadingFile(true);
            const mediaFiles = [...media];
    
            // Display initial notification
            notification.info({
                key: "uploadProgress",
                message: "Uploading Files",
                description: "Preparing upload...",
                duration: 0, // Keep it open until manually closed
                style: { marginTop: "15vh" },
            });
    
            await Promise.all(
                mediaFiles.map(async (file) => {
                    const fileSuffix = uuid();
                    const fixedFilename = file.name.replace(/\s+/g, '_');
                    const filename = `${fileSuffix}_${fixedFilename}`;
                    const key = `${event.directory}/${filename}`
                    const { data } = await getRequest({ fileName: filename, fileType: file.type, key, eventId }, undefined, token, "guest/uploads/url", true)
                    const { uploadURL, publicURL } = data

                    // Track progress
                    await axios.put(uploadURL, file, {
                        headers: { 'Content-Type': file.type },
                        onUploadProgress: (progressEvent) => {
                            const { loaded } = progressEvent;
                            const uploadedBytes = loaded;
    
                            const progressPercent = Math.floor((uploadedBytes / file.size) * 100);
                            if(progressPercent === 100) {
                                notification.success({
                                    key: `uploadProgress_${fixedFilename}`,
                                    message: `Finished Uploading ${fixedFilename}`,
                                    description: <Progress percent={progressPercent} status="active" />,
                                    style: { marginTop: "15vh" },
                                });
                                files.push({
                                    key,
                                    filename,
                                    takenAt: file.lastModifiedDate,
                                    link: publicURL,
                                    type: capitalize(file.type.split("/")[0]),
                                    caption: formData.get(`caption_${fixedFilename}`),
                                });
                            } else {
                                notification.open({
                                    key: `uploadProgress_${fixedFilename}`,
                                    message: `Uploading ${fixedFilename}`,
                                    description: <Progress percent={progressPercent} status="active" />,
                                    duration: 0,
                                    style: { marginTop: "15vh" },
                                });
                            }
                        },
                    });
                })
            );
    
            console.log(files);
            setUploadingFile(false);
    
            // Close the progress notification
            notification.success({
                key: "uploadProgress",
                message: "Upload Completed",
                description: "All files have been uploaded successfully.",
                style: { marginTop: "15vh" },
            });
    
            const creation = {
                eventId,
                folderId: formData.get("folderId") === "" ? undefined : formData.get("folderId"),
                files,
            };
    
            uploadFiles(creation, navigate);
        } catch (err) {
            console.error(err);
    
            // Handle upload errors
            notification.error({
                key: "uploadProgress",
                message: "Upload Failed",
                description: "An error occurred during file upload.",
                style: { marginTop: "15vh" },
            });
        }
    };



    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Upload Audio
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ ...centered_flex_box, flexDirection: "column", width: "100%" }}>
                    <ReactFileReader
                        multipleFiles={true}
                        handleFiles={handleAddFiles}
                        fileTypes={fileTypes}
                    >
                        <Button fullWidth sx={{ my: 1, ...main_button }}>
                            {!fetchingFile ? <>
                                <Mic sx={{ mx: 1 }} /> Select Audio
                            </> : <>
                                <CircularProgress />
                            </>}
                        </Button>
                    </ReactFileReader>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={uploadingFile || [...media].length === 0}
                        sx={{ mt: 3, mb: 2, width: "90vw", ...main_button }}
                    >
                        {!uploadingFile ? <>
                            <Upload sx={{ mx: 1 }} /> Upload Audio
                        </> : <>
                            <CircularProgress />
                        </>}
                    </Button>
                    <RecordAudioCard filesLength={media.length} addAudioElement={handleAddFiles} />
                    <hr />
                    <Grid container spacing={2} justifyContent="center">
                        {[...media]?.map((file) => {
                            return (
                                <Grid item xs={isMobile ? 12 : 6}>
                                    <Card sx={{ minHeight: 300 }}>
                                        <CardHeader
                                            subheader={capitalize(file.type.split("/")[0])}
                                            titleTypographyProps={{ align: 'center' }}
                                            action={<IconButton onClick={() => handleRemoveFile(file.name)}><Delete /></IconButton>}
                                            subheaderTypographyProps={{
                                                align: 'center',
                                            }}
                                        />
                                        <CardContent sx={{...centered_flex_box}}>
                                            {file.type.startsWith("audio/") && <audio autoPlay={false} controls src={URL.createObjectURL(file)} alt={file.name} style={{ maxWidth: '100%', maxHeight: '100%' }} />}
                                        </CardContent>
                                        <CardContent>
                                            <MainInput
                                                margin="normal"
                                                fullWidth
                                                multiline
                                                minRows={2}
                                                inputProps={{
                                                    maxlength: 144,
                                                }}
                                                name={`caption_${file.name.replace(/\s+/g, '_')}`}
                                                label="Caption"
                                                id={`caption_${file.name.replace(/\s+/g, '_')}`}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}

const mapStateToProps = (state) => ({
    token: state?.auth?.token,
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    event: state?.records?.event,
});

const mapDispatchToProps = { getEvent, uploadFiles };

export default connect(mapStateToProps, mapDispatchToProps)(RecordAudio);