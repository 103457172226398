import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwittwerIcon from "@mui/icons-material/Twitter";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { connect } from "react-redux";
import { logout } from "../store/actions/authActions";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Grid, Link, Tooltip } from "@mui/material";
import { centered_flex_box, left_flex_box, right_flex_box } from "./Styles";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { UserLogin } from "../../Pages";
import StarsIcon from "@mui/icons-material/Stars";
import YouTube from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import { Instagram, Mail, Phone } from "@mui/icons-material";

function Footer({ auth }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const token = auth?.token || "";
  const header = token.split(" ");
  const role = header[0];
  const location = useLocation();

  const route = (path) => {
    navigate(path);
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "var(--secColor)",
          color: "var(--appBg)",
          width: "100vw",
          padding: 5,
          paddingBottom: 0,
          mt: 2,
        }}
      >
        <Grid container spacing={isMobile ? 5 : 10} mb={2}>
          <Grid item xs={isMobile ? 12 : 6}>
            <Typography fontSize={20} fontWeight="bold">
              About Us
            </Typography>
            <Typography textAlign="justify" fontWeight="light">
              POV Pix is a digital guestbook with unlimited storage to hold all photos, videos, voice messages and guestbook messages from your special day. Long gone are the days of full storage and trying to collect all media from your event. POV Pix provides you with a QR code that guests can scan and upload media from their browsers (No App or Signup required)
            </Typography>
          </Grid>
          {/* <Grid item xs={isMobile ? 12 : 3}>
                        <Typography fontSize={20} fontWeight="bold">Follow Us</Typography>
                        <Grid container>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <FacebookIcon fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <InstagramIcon fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <TwittwerIcon fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <YouTube fontSize='large' sx={{ color: "var(--mainWhite)" }} />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <FaTiktok size="30" color="var(--mainWhite)" />
                                </IconButton>
                            </Grid>
                            <Grid item xs={4} sx={centered_flex_box}>
                                <IconButton>
                                    <FaLinkedin size="30" color="var(--mainWhite)" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid> */}
          <Grid item xs={isMobile ? 12 : 2}>


          </Grid>
          <Grid item xs={isMobile ? 12 : 4}>
            <Box padding={1}>
              <Typography fontSize={20} fontWeight="bold">
                Follow Us
              </Typography>
              <Instagram />{" "}
              <NavLink
                to={"https://www.instagram.com/pov.pix/"}
                target="_blank"
              >
                POV Pix
              </NavLink>
              <Typography textAlign="justify">
                <WhatsAppIcon />{" "}
                <NavLink
                  to={"https://wa.me/201207325333"}
                  target="_blank"
                >
                  POV Pix
                </NavLink>
              </Typography>
              <Typography textAlign="justify">
                <Phone />{" "}
                <NavLink
                  to={"tel:+201207325333"}
                  target="_blank"
                >
                  +201207325333
                </NavLink>
              </Typography>
              <Typography textAlign="justify">
                <Mail />{" "}
                <NavLink
                  to={"mailto:info@pov-pix.com"}
                  target="_blank"
                >
                  info@pov-pix.com
                </NavLink>
              </Typography>
            </Box>
            <Box padding={1}>
              <Typography fontSize={20} fontWeight="bold">
                Policies
              </Typography>
              <Typography textAlign="justify">
                <NavLink
                  to={"/policy/privacy"}
                  replace
                >
                  Privacy Policy
                </NavLink>
              </Typography>
              <Typography textAlign="justify">
                <NavLink
                  to={"/policy/refund"}
                  replace
                >
                  Refund Policy
                </NavLink>
              </Typography>
              <Typography textAlign="justify">
                <NavLink
                  to={"/terms"}
                  replace
                >
                  Terms & Conditions
                </NavLink>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <hr
          style={{
            borderTop: "solid 2px var(--appBg)",
            opacity: "100%",
            margin: 0,
          }}
        />
      </Box>
      <Box
        sx={{
          bgcolor: "var(--secColor)",
          color: "var(--appBg)",
          width: "100vw",
          padding: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography fontWeight="bold">
              © 2024 POV Pix All Rights Reserved
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ ...centered_flex_box, flexDirection: "column" }}
          >
            {/* <Typography fontWeight="bold">
              <NavLink className="a2" to="/terms">
                {"Terms & Conditions"}
              </NavLink>
            </Typography>
            <Typography fontWeight="bold">
              <NavLink className="a2" to="/policy">
                {"Privacy Policy"}
              </NavLink>
            </Typography> */}
          </Grid>
          <Grid item xs={4} sx={right_flex_box}>
            <Typography fontWeight="bold">
              <NavLink
                to={"https://linktr.ee/integra.tech"}
                target="_blank"
              >
                Developed By Integra
              </NavLink>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state?.auth,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
