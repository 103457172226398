async function uploadFile(params, onProgress) {
    
}

async function deleteObject(params) {
    
}

async function getObject(params) {
    
}

async function getPresignedUrl(params) {
}

async function generatePresignedUrl(params) {
}

export { uploadFile, deleteObject, getObject, getPresignedUrl, generatePresignedUrl };