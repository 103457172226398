import * as React from 'react';
import Logo from "../../app/components/logo.png"
import { Typography, Box, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, InputAdornment, IconButton, ImageList, ImageListItem, Grid, Card, CardContent, CardHeader, Pagination, CardActions, Chip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, SecInput, sec_button, ter_button } from '../../app/components/Styles';
import { Image, notification } from 'antd';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { getEvent, getFolders, getUploads, getUploadsEmbed } from '../../app/store/actions/guestActions';
import { isMobile } from 'react-device-detect';
import { Album, Book, Collections, Delete, Download, Folder, Inbox, LibraryMusic, MenuBook, Mic, Notifications, Photo, Upload, Videocam } from '@mui/icons-material';
import { useState } from 'react';
import ReactFileReader from "react-file-reader";
import { capitalize } from 'lodash';
import { v4 as uuid } from 'uuid';
import { config } from '../../app/config';
import { uploadFile } from '../../core/aws';
import { useEffect } from 'react';
import moment from 'moment';
import GalleryCard from '../../app/components/GalleryCard';

export const MyUploads = ({ getEvent, isLoading, event, getUploads, getUploadsEmbed, uploads, deleteFile, folders, getFolders }) => {

    const { eventId, cookieId } = useParams()
    const navigate = useNavigate();

    const initialGalleryFilters = {
        page: 1,
        limit: 20,
        eventId,
        onlyMine: true,
        folderId: undefined,
        type: undefined,
    }
    const [galleryFilters, setGalleryFilters] = useState(initialGalleryFilters)
    const [action, setAction] = useState(false)
    const { page, limit } = galleryFilters

    const handlePageChange = (event) => {
        setGalleryFilters({ ...galleryFilters, page: parseInt(event.target.innerText) })
    }

    useEffect(() => {
        getEvent({ eventId })
        getFolders({ eventId })
    }, [eventId])

    useEffect(() => {
        if (window.location.pathname.includes("embed")) {
            if (cookieId) {
                getUploadsEmbed({ ...galleryFilters, cookieId })
            }
        } else {
            getUploads(galleryFilters)
        }
    }, [galleryFilters, action, cookieId])

    if (isLoading) {
        return (
            <Box sx={{ ...centered_flex_box }}>
                <CircularProgress sx={{ color: "var(--secColor)" }} />
            </Box>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    My Uploads
                </Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "row", width: "100%" }}>
                    <Grid container sx={{ ...centered_flex_box }} rowSpacing={1}>
                        {folders?.length > 0 && <Grid item><Chip sx={{ mx: 1 }} icon={<Folder />} label={"All"} variant={galleryFilters.folderId === undefined ? "filled" : "outlined"} onClick={() => setGalleryFilters({ ...galleryFilters, folderId: undefined })} /></Grid>}
                        {folders?.map((folder) => {
                            return <Grid item><Chip sx={{ mx: 1 }} icon={<Folder />} label={folder.name} variant={folder._id === galleryFilters.folderId ? "filled" : "outlined"} onClick={() => setGalleryFilters({ ...galleryFilters, folderId: folder._id })} /></Grid>
                        })}
                    </Grid>
                </Box>
                <hr />
                <Image.PreviewGroup>
                    <Box sx={{ padding: 2 }}>
                        <Grid container spacing={2} padding={2} justifyContent="center">
                            {uploads?.docs?.map((file) => {
                                return (
                                    <GalleryCard file={file} eventId={eventId} event={event} action={action} setAction={setAction} guest={true} cookieId={cookieId} />
                                )
                            })}
                            {uploads?.docs?.length === 0 && (
                                <Grid item xs={12} sx={{ ...centered_flex_box, flexDirection: "column" }}>
                                    <Inbox fontSize="large" />
                                    <Typography textAlign="center" fontSize={40}>Gallery Is Empty</Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Box sx={{ ...centered_flex_box, mx: 1, p: 1 }}>
                            <Pagination count={parseInt(uploads?.pages) || 1} page={page} onChange={handlePageChange} />
                        </Box>
                    </Box>
                </Image.PreviewGroup>
            </Box>
        </ >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    event: state?.records?.event,
    uploads: state?.records?.uploads,
    folders: state?.records?.folders,
});

const mapDispatchToProps = { getEvent, getUploads, getUploadsEmbed, getFolders };

export default connect(mapStateToProps, mapDispatchToProps)(MyUploads);