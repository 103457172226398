import { Celebration, Delete, Diamond, Download, Email, ExpandMore, Facebook, Message, MoreHoriz, MusicNote, Phone, Send, Star, StarOutline, Upload, WhatsApp } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { MainInput, centered_flex_box, main_button, reject_button, right_flex_box, sec_button } from "./Styles";
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Image, notification, Progress } from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { deleteFile, editFile, starFile } from "../store/actions/usersActions";
import { deleteFile as guestDelete, editFile as guestEdit } from "../store/actions/guestActions";
import moment from "moment";
import { uploadFile, deleteObject, getObject, getPresignedUrl } from "../../core/aws";
import { config } from "../config";
import ImageComponent from "./ImageComponent";
import download from "downloadjs";
import axios from "axios";
import Mime from 'mime';
import html2canvas from "html2canvas";

function timeout(delay) {
    return new Promise(res => setTimeout(res, delay));
}

function GalleryCard({ user, file, eventId, event, editFile, deleteFile, guestEdit, guestDelete, action, setAction, guest, cookieId, starFile }) {

    const [addCaption, setAddCaption] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (ev) => {
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const guestCookie = Cookies.get(`guestId_${eventId}`) || cookieId;
    console.log(guestCookie)

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const fileInfo = {
            eventId,
            uploadId: file._id,
            caption: data.get('caption')
        }
        setAddCaption(false)
        if (guest) {
            guestEdit(fileInfo)
        } else {
            editFile(fileInfo)
        }
        timeout(100)
        setAction(!action)
    }

    const handleDelete = async (event) => {
        event.preventDefault();
        if (guest) {
            await guestDelete({ uploadIds: [file._id], eventId }, file)
        } else {
            await deleteFile({ uploadIds: [file._id], eventId }, file)
        }
        timeout(100)
        setAction(!action)
    }

    const handleStar = async (event) => {
        event.preventDefault();
        starFile({ eventId, uploadId: file._id, star: file.starred ? false : true })
        timeout(200)
        setAction(!action)
    }

    const handleDownload = async (key, filename) => {
        let progress = 0;
    
        // Create and show the notification with a progress bar
        const notificationKey = `download_${key}`;
        notification.open({
            key: notificationKey,  // Use this key to refer to the notification later
            message: 'Downloading File',
            description: (
                <Progress percent={progress} status="active" />
            ),
            duration: 0, // Keep the notification open indefinitely until it is closed
            style: { marginTop: "15vh" },
        });
    
        try {
            const params = {
                Bucket: config.bucketName,
                Key: key,
            };
    
            const url = await getPresignedUrl(params);
            console.log("File URL:", url);
    
            const response = await fetch(url);
            if (!response.ok) throw new Error("Failed to fetch the object");
    
            // Track progress manually
            const contentLength = response.headers.get('content-length');
            if (!contentLength) {
                throw new Error("Unable to get file size for progress tracking.");
            }
    
            const total = parseInt(contentLength, 10);
            let loaded = 0;
    
            const reader = response.body.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    function push() {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
    
                            loaded += value.length;
                            progress = Math.round((loaded / total) * 100);
    
                            // Update progress in the notification
                            notification.open({
                                key: notificationKey,  // Use the same key to update progress
                                message: 'Downloading File',
                                description: (
                                    <Progress percent={progress} status="active" />
                                ),
                                duration: 0,  // Keep it open
                                style: { marginTop: "15vh" },
                            });
    
                            controller.enqueue(value);
                            push();
                        });
                    }
                    push();
                },
            });
    
            const newResponse = new Response(stream);
            const blob = await newResponse.blob();
    
            // Create a file from the blob
            const file = new File([blob], filename, { type: Mime.getType(filename) });
    
            if (isMobile) {
                if (navigator.share && navigator.canShare({ files: [file] })) {
                    await navigator.share({
                        title: filename,
                        files: [file],
                    });
                } else {
                    notification.error({
                        message: "Sharing Not Supported",
                        description: "Your browser does not support file sharing.",
                        style: { marginTop: "15vh" },
                    });
                }
            } else {
                // Use download.js for desktop download
                download(file, filename, Mime.getType(filename));
                notification.success({ message: "File Downloaded", style: { marginTop: "15vh" } });
            }
    
            // Close the progress notification
            notification.destroy(notificationKey);  // Close the notification using the key
        } catch (error) {
            console.error('Error handling download:', error.message);
            notification.error({
                key: notificationKey,  // Ensure the error notification uses the same key
                message: "Error Downloading File",
                description: error.message,
                style: { marginTop: "15vh" },
            });
        }
    };

    return (
        <Grid item xs={isMobile ? 6 : 2} sx={{width: "100%"}}>
            <Card sx={{ height: (addCaption ? 100 : 0) + (isMobile ? 350 : 500), width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <CardContent sx={{ ...centered_flex_box, flexDirection: "column", padding: "8px !important" }}>
                    <Box sx={{ ...right_flex_box, justifyContent: "space-between", width: "100%" }}>
                        <Box>
                            {user?._id === event?.user && (
                                <IconButton onClick={handleStar}>{file.starred ? <Star sx={{ color: "var(--secColor)" }} /> : <StarOutline />}</IconButton>
                            )}
                            {user?._id !== event?.user && file.starred && <Star sx={{ color: "var(--secColor)" }} />}
                        </Box>
                        <Box>
                            <IconButton onClick={handleClick}><MoreHoriz /></IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                {guest && file.guest?._id === guestCookie && (
                                    <MenuItem sx={{ color: "var(--secColor)" }} onClick={handleDelete}><Delete /><Typography>Delete File</Typography></MenuItem>
                                )}
                                {user?._id === event?.user && (
                                    <MenuItem sx={{ color: "var(--secColor)" }} onClick={handleDelete}><Delete /><Typography>Delete File</Typography></MenuItem>
                                )}
                                {(user?._id === event?.user || event?.allowDownloads) && (
                                    <MenuItem sx={{ color: "var(--secColor)" }} onClick={() => handleDownload(file.key, file.filename, file.link)}><Download /><Typography>Download File</Typography></MenuItem>
                                )}
                                {guest && file.guest?._id === guestCookie && !addCaption && !file.caption && (
                                    <MenuItem onClick={() => setAddCaption(true)} sx={{ color: "var(--secColor)" }}><Message /><Typography>Add Caption</Typography></MenuItem>
                                )}
                                {user?._id === event?.user && !file.caption && (
                                    <MenuItem onClick={() => setAddCaption(true)} sx={{ color: "var(--secColor)" }}><Message /><Typography>Add Caption</Typography></MenuItem>
                                )}

                            </Menu>
                        </Box>
                    </Box>
                    {file.type === "Image" && <Image id="mediaDisplay" src={file.link} alt={file.caption} width={isMobile ? 150 : 300} height={isMobile ? 150 : 300} preview={true} />}
                    {file.type === "Video" && <video id="mediaDisplay" autoPlay={false} controls src={file.link} alt={file.caption} style={{ width: isMobile ? 150 : 300, height: isMobile ? 150 : 300 }} />}
                    {file.type === "Audio" && <Box sx={{ width: isMobile ? 150 : 300, height: isMobile ? 150 : 300, ...centered_flex_box, flexDirection: "column" }}>
                        <MusicNote sx={{ width: isMobile ? 150 : 300, height: isMobile ? 110 : 260 }} />
                        <audio id="mediaDisplay" autoPlay={false} controls src={file.link} alt={file.caption} style={{ width: isMobile ? 150 : 300, height: 40 }} />
                    </Box>}
                </CardContent>
                <CardContent>
                    {(guest && file.guest?._id === guestCookie &&
                        (file.caption !== "" ? <>
                            <Typography fontStyle="italic">"{file.caption}"</Typography>
                        </> : <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                            {addCaption && <Box component="form" onSubmit={handleSubmit}>
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    required
                                    minRows={2}
                                    inputProps={{
                                        maxlength: 144,
                                    }}
                                    name={"caption"}
                                    label="Caption"
                                    id={"caption"}
                                />
                                <Box sx={{ ...centered_flex_box }}>
                                    <Button type="submit" sx={{ ...sec_button, mr: 1 }}>Submit</Button>
                                    <Button sx={{ ...sec_button }} onClick={() => setAddCaption(false)} >Cancel</Button>
                                </Box>
                            </Box>}
                        </Box>))}
                    {(!guest &&
                        (file.caption !== "" ? <>
                            <Typography fontStyle="italic">"{file.caption}"</Typography>
                        </> : <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                            {addCaption && <Box component="form" onSubmit={handleSubmit}>
                                <MainInput
                                    margin="normal"
                                    fullWidth
                                    multiline
                                    required
                                    minRows={2}
                                    inputProps={{
                                        maxlength: 144,
                                    }}
                                    name={"caption"}
                                    label="Caption"
                                    id={"caption"}
                                />
                                <Box sx={{ ...centered_flex_box }}>
                                    <Button type="submit" sx={{ ...sec_button, mr: 1 }}>Submit</Button>
                                    <Button sx={{ ...sec_button }} onClick={() => setAddCaption(false)} >Cancel</Button>
                                </Box>
                            </Box>}
                        </Box>))}
                    <Typography>~{file.guest?.name || file.user?.name || "You"}</Typography>
                </CardContent>
                <CardContent sx={{ padding: "5px !important" }}>
                    {file?.folder && <Typography>{file.folder.name}</Typography>}
                    <Typography fontWeight="light" fontSize={12} color={"text.secodary"} fontStyle={"italic"}>{moment(file.takenAt || file.createdAt).format("DD/MM/YYYY HH:mm")}</Typography>
                </CardContent>
                {/* <CardActions sx={{ display: 'flex', justifyContent: "center" }}>
                    {guest && file.guest?._id === guestCookie && <>
                        <IconButton onClick={handleDelete}><Delete /></IconButton>
                    </>}
                    {!guest && <>
                        <IconButton onClick={handleDelete}><Delete /></IconButton>
                    </>}
                    {(!guest || event?.allowDownloads) && <IconButton onClick={() => download(file.link)}><Download /></IconButton>}
                </CardActions> */}
            </Card>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
});

const mapDispatchToProps = { deleteFile, editFile, guestDelete, guestEdit, starFile };

export default connect(mapStateToProps, mapDispatchToProps)(GalleryCard);