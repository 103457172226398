import { WAIT, WAIT_SUCCESS, WAIT_FAIL, FETCH_RECORDS, FETCH_RECORDS_SUCCESS, FETCH_RECORDS_FAIL, LOGOUT, LOGOUT_SUCCESS } from "./types";
import { delRequest, getRequest, postRequest, putRequest } from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification, Progress } from "antd";
import download from "downloadjs";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { config } from "../../config";
import { deleteObject } from "../../../core/aws";
import { isMobile } from "react-device-detect";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver"

const currentLocale = Cookies.get("i18next") || "en";

export const deleteAccount = (data) => (dispatch, useState) => {
    dispatch({ type: LOGOUT });
    const token = useState()?.auth?.token

    delRequest({ ...data, locale: currentLocale }, undefined, token, endpoints.user.delete)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data.message, style: { marginTop: "15vh" } })
            return dispatch({
                type: LOGOUT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
        });
};

export const checkPromo = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.checkPromo)
        .then((response) => {
            const { data } = response;
            notification.success({ message: "Promo Code Applied", style: { marginTop: "15vh" } })
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const checkout = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.checkout)
        .then((response) => {
            const { data } = response;
            const { redirect } = data;
            window.location.href = redirect
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const checkTransaction = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.checkTransaction)
        .then((response) => {
            const { data } = response;
            dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getOrders = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.orders.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const getEvents = (data, store) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.root)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data,
                store
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};

export const createEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.event.root)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate(`/user/events/${data?.newEvent._id}`);
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const editEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.user.event.root)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate(-1);
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const getFolders = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.folders)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const getUploadURLs = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const uploadFiles = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS,
            });
            navigate && navigate(-1)
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const getUploads = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const editFile = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const starFile = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    putRequest(data, undefined, undefined, token, endpoints.user.event.star)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const deleteFile = (data, file) => async (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    delRequest(data, undefined, token, endpoints.user.event.upload)
        .then((response) => {
            const { data } = response;

            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: WAIT_FAIL });
        });
};

export const downloadGallery = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token;
    let progress = 0;

    // Open the initial notification with a progress bar
    const notificationKey = `download_${data.eventId}`;
    notification.open({
        key: notificationKey,  // Use a unique key to identify the notification
        message: "This May Take a While",
        description: <Progress percent={progress} status="active" />,
        duration: 0, // Keep the notification open indefinitely until the download is complete
        style: { marginTop: "15vh" },
    });

    if (isMobile) {
        notification.info({
            message: "It is highly suggested to use a PC for downloads",
            style: { marginTop: "15vh" },
        });
    }

    // Make the request to fetch the gallery, passing in the progress handler
    getRequest({ ...data, responseType: "arraybuffer", onProgress: (newProgress) => {
        // Update the progress in the notification
        progress = newProgress;
        notification.open({
            key: notificationKey, // Use the same key to update progress
            message: "Downloading File",
            description: <Progress percent={progress} status="active" />,
            duration: 0,  // Keep it open
            style: { marginTop: "15vh" },
        });
    }}, undefined, token, endpoints.user.event.export)
        .then((response) => {
            notification.success({ message: "All Downloaded", style: { marginTop: "15vh" } });
            download(response.data, response.headers["filename"], "application/zip");
            return dispatch({
                type: WAIT_SUCCESS
            });
        })
        .catch((err) => {
            notification.error({
                message: err?.response?.data?.message || "Something Went Wrong :(",
                style: { marginTop: "10vh" },
            });
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const downloadGalleryAlt = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token;

    let progress = 0;
    const notificationKey = `download_${data.eventId}`;

    // Open the initial notification with a progress bar
    notification.open({
        key: notificationKey,
        message: "This May Take a While",
        description: <Progress percent={progress} status="active" />,
        duration: 0, // Keep open indefinitely until the download is complete
        style: { marginTop: "15vh" },
    });

    if (isMobile) {
        notification.info({
            message: "It is highly suggested to use a PC for downloads",
            style: { marginTop: "15vh" },
        });
    }

    // Step 1: Request pre-signed URLs from the server
    getRequest(data, undefined, token, endpoints.user.event.export_urls)
        .then((response) => {
            const { files: presignedUrls, eventName } = response.data;

            if (!presignedUrls || presignedUrls.length === 0) {
                notification.error({
                    message: "No Files",
                    description: "No files available for download.",
                });
                dispatch({ type: "WAIT_DONE" });
                return;
            }

            const filesPerZip = 50; // Maximum files per ZIP
            const totalFiles = presignedUrls.length;
            const zipCount = Math.ceil(totalFiles / filesPerZip);
            let completed = 0;

            if(presignedUrls.length > filesPerZip) {
                notification.info({
                    message: "Multiple files",
                    description: `Your media will be downloaded over ${zipCount} zip files`,
                });
            }

            const updateProgress = () => {
                progress = Math.floor((completed / totalFiles) * 100);
                notification.open({
                    key: notificationKey,
                    message: "Downloading Files",
                    description: <Progress percent={progress} status="active" />,
                    duration: 0,
                    style: { marginTop: "15vh" },
                });
            };

            const downloadFiles = async () => {
                for (let part = 0; part < zipCount; part++) {
                    const zip = new JSZip();
                    const start = part * filesPerZip;
                    const end = Math.min(start + filesPerZip, totalFiles);
                    const filesBatch = presignedUrls.slice(start, end);

                    for (const file of filesBatch) {
                        try {
                            console.log(`Downloading ${file.filename}`)
                            const response = await axios.get(file.url, {
                                responseType: "blob",
                            });
                            zip.file(file.filename, response.data);
                            
                            if(file.caption) {
                                const metadataContent = `Caption: ${file.caption}\nUploader: ${file.uploader}`;
                                const metadataFilename = `${file.filename}_caption.txt`;
    
                                zip.file(metadataFilename, metadataContent);
    
                                console.log(`Downloaded ${file.filename} and added metadata`);
                            } else {
                                console.log(`Downloaded ${file.filename}`);
                            }

                            completed += 1;
                            updateProgress();
                        } catch (err) {
                            console.error(`Error downloading file ${file.filename}:`, err.message);
                            notification.warning({
                                message: "Download Skipped",
                                description: `Could not download ${file.filename}. Skipping.`,
                            });
                        }
                    }

                    // Finalize and save the current ZIP part
                    const zipBlob = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
                    const partName = `${eventName || "gallery"}-files_Part${part + 1}.zip`;
                    saveAs(zipBlob, partName);
                }

                notification.success({
                    message: "All Files Downloaded",
                    description: "Your files have been downloaded successfully.",
                    style: { marginTop: "15vh" },
                });

                dispatch({ type: "WAIT_DONE" });
            };

            downloadFiles();
        })
        .catch((err) => {
            notification.error({
                message: err?.response?.data?.message || "Something Went Wrong :(",
                style: { marginTop: "10vh" },
            });
            console.error(err);
            dispatch({ type: WAIT_FAIL });
        });
};

export const getMessages = (data) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    getRequest(data, undefined, token, endpoints.user.event.message)
        .then((response) => {
            const { data } = response;
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } });
            console.log(err);
            return dispatch({ type: FETCH_RECORDS_FAIL });
        });
};

export const transferEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.event.transfer)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate("/user/events");
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const shareEvent = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.event.share)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            navigate && navigate("/user/events");
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const sendRequest = (data, close) => (dispatch, useState) => {
    dispatch({ type: WAIT });
    const token = useState()?.auth?.token

    postRequest(data, undefined, undefined, token, endpoints.user.printingRequest)
        .then((response) => {
            const { data } = response;
            notification.success({ message: data?.message, style: { marginTop: "15vh" } });
            dispatch({
                type: WAIT_SUCCESS
            });
            close && close();
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: WAIT_FAIL,
            });
        });
};

export const redeemVoucher = (data, navigate) => (dispatch, useState) => {
    dispatch({ type: FETCH_RECORDS });
    const token = useState()?.auth?.token

    postRequest(data, { locale: currentLocale }, undefined, token, endpoints.user.redeem)
        .then((response) => {
            const { data } = response;
            const { order, message } = data;
            notification.success({ message: message, style: { marginTop: "15vh" } })
            navigate && navigate(`/user/events/create/${order._id}`);
            return dispatch({
                type: FETCH_RECORDS_SUCCESS,
                payload: data
            });
        })
        .catch((err) => {
            notification.error({ message: err?.response?.data?.message, style: { marginTop: "15vh" } })
            console.log(err);
            return dispatch({
                type: FETCH_RECORDS_FAIL,
            });
        });
};
