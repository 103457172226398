import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE;

const postRequest = async (body, query, params, token, endPoint, withCredentials) => {
  return await axios.post(
    `${baseURL}/${endPoint}${params ? params : ""}`,
    body,
    {
      params: query,
      headers: {
        "Accept": "json",
        "x-access-token": token
      },
      responseType : query?.responseType,
      withCredentials
    },
  );
};

const getRequest = async (query, params, token, endPoint, withCredentials) => {
  return await axios.get(`${baseURL}/${endPoint}${params ? params : ""}`, {
    params: query,
    headers: {
      "Accept": "json",
      "x-access-token": token
    },
    responseType : query?.responseType,
    withCredentials,
    onDownloadProgress: (progressEvent) => {
      // Calculate the progress percentage
      const total = progressEvent.total;
      const loaded = progressEvent.loaded;
      const progress = Math.round((loaded / total) * 100);
      if (query?.onProgress) {
          query.onProgress(progress); // Pass progress to the caller
      }
    }
  });
};

const delRequest = async (query, params, token, endPoint, withCredentials) => {
  return await axios.delete(`${baseURL}/${endPoint}${params ? params : ""}`, {
    params: query,
    headers: {
      "Accept": "json",
      "x-access-token": token
    },
    responseType : query?.responseType,
    withCredentials
  });
};

const putRequest = async (body, query, params, token, endPoint, withCredentials) => {
  return await axios.put(
    `${baseURL}/${endPoint}${params ? params : ""}`,
    body,
    {
      params: query,
      headers: {
        "Accept": "json",
        "x-access-token": token
      },
      withCredentials
    }
  );
};

export { postRequest, getRequest, delRequest, putRequest };
