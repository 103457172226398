import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  CircularProgress,
  Chip,
  Tooltip,
  IconButton,
  Card,
  CardMedia,
  CardActions,
  CardHeader,
} from "@mui/material";
import { connect } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AWS from 'aws-sdk';
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainTextArea,
  main_button,
  reject_button,
  sec_button,
  StyledInput,
  MainDateInput,
  MainDateTimeInput,
  right_flex_box,
  left_flex_box,
} from "../../app/components/Styles";
import { useNavigate, useParams } from "react-router-dom";
import ReactFileReader from "react-file-reader";
import { editEvent, getOrders, getEvents } from "../../app/store/actions/usersActions";
import { config } from "../../app/config";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 as uuid } from 'uuid';
import { uploadFile } from "../../core/aws";
import dayjs from "dayjs";
import { Close, Info } from "@mui/icons-material";
import BundleInfoModal from "../../app/components/BundleInfoModal";
import { Image } from "antd";
import ImageComponent from "../../app/components/ImageComponent";
import { getRequest } from "../../core/network";
import axios from "axios";

export const EditEvent = ({ event, editEvent, getEvents, isLoading, token }) => {
  window.Buffer = window.Buffer || require("buffer").Buffer;

  const navigate = useNavigate();
  const eventId = useParams().eventId

  const [cover, setCover] = React.useState(null);
  const [allowDownloads, setAllowDownloads] = React.useState(false);
  const [privateGallery, setPrivateGallery] = React.useState(false);
  const [date, setDate] = React.useState(null);
  const [fetchingFile, setFetchingFile] = React.useState(false);
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);

  const handleAddImage = (files) => {
    setFetchingFile(true);
    let file = files[0];
    setCover(file);
    setFetchingFile(false);
  };

  const handleDeleteImage = () => {
    setCover(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      isLoading = true;
      const directory = event?.directory
      var coverURL;

      setUploadingFile(true);
      if (cover) {
        const fileSuffix = uuid();
        const filename = `${fileSuffix}_${cover.name.replace(/\s+/g, '_')}`
        const key = `${directory}/${filename}`
        const { data } = await getRequest({ fileName: filename, fileType: cover.type, key }, undefined, token, "user/uploads/url")
        const { uploadURL, publicURL } = data
        await axios.put(uploadURL, cover, {
          headers: { 'Content-Type': cover.type },
        });
        coverURL = publicURL;
      }
      setUploadingFile(false);

      const creation = {
        cover: coverURL,
        name: data.get("name"),
        welcomeMessage: data.get("welcomeMessage"),
        ...(moment().isAfter(event?.date) ? {} : { date }),
        private: privateGallery,
        allowDownloads,
        eventId,
      };

      editEvent(creation, navigate);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getEvents({ eventId })
  }, [eventId])

  if (isLoading) {
    return (
      <Box sx={{ ...centered_flex_box, minHeight: "100vh" }}>
        <CircularProgress sx={{ color: "var(--secColor)" }} />
      </Box>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8 }}>
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Edit "{event?.name}"
          </Typography>
          <BundleInfoModal open={showInfo} handleClose={() => setShowInfo(false)} bundle={event?.bundle} />
          <Typography
            component="h1"
            variant="h4"
            align="center"
            color="text.primary"
            gutterBottom
          >
            {event?.bundle?.name} Bundle <IconButton onClick={() => setShowInfo(true)}><Info /></IconButton>
          </Typography>
        </Container>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, ...left_flex_box, flexDirection: "column" }}>
          <ReactFileReader
            multipleFiles={false}
            handleFiles={handleAddImage}
            fileTypes={["image/*"]}
          >
            <Button fullWidth sx={{ my: 1, ...main_button }}>
              {!fetchingFile ? <>
                <UploadIcon /> Upload Event Cover Image
              </> : <>
                <CircularProgress />
              </>}
            </Button>
          </ReactFileReader>
          {cover ? (
            <Card sx={{ p: 2, ...centered_flex_box, flexDirection: "column" }}>
              <CardHeader
                title={cover.name}
                titleTypographyProps={{ align: 'center' }}
                action={<IconButton onClick={handleDeleteImage} ><Close /></IconButton>}
              />
              <CardMedia>
                <ImageComponent width="80vw" src={URL.createObjectURL(cover)} alt={cover.name} />
              </CardMedia>
            </Card>
          ) : (
            <Card sx={{ p: 2, ...centered_flex_box, flexDirection: "column" }}>
              <CardHeader
                title={"Current Cover"}
                titleTypographyProps={{ align: 'center' }}
              />
              <CardMedia>
                <Image width="80vw" src={event?.cover} alt={"Current Cover"} />
              </CardMedia>
            </Card>
          )}
          <MainInput
            margin="normal"
            required
            fullWidth
            id="name"
            label="Event Title"
            name="name"
            defaultValue={event?.name}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MainDateInput
              minDate={dayjs(moment())}
              sx={{ width: "100%" }}
              defaultValue={dayjs(moment(event?.date))}
              readOnly = {moment().isAfter(moment(event?.date))}
              label="Event Date"
              id="date"
              name="date"
              onChange={({ $d }) => setDate(moment($d).startOf("day"))}
            />
          </LocalizationProvider>
          <MainInput
            margin="normal"
            fullWidth
            required
            multiline
            minRows={3}
            inputProps={{
              maxlength: 144,
            }}
            defaultValue={event?.welcomeMessage}
            name="welcomeMessage"
            label="Welcome Message (144 Characters Max)"
            id="welcomeMessage"
          />
          <Tooltip title="Check if you don't want your guests to view all uploads">
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  value={privateGallery}
                  onChange={() => setPrivateGallery(!privateGallery)}
                  name="privateGallery"
                  id="privateGallery"
                />
              }
              label="Private Gallery"
              defaultChecked={event?.private}
            />
          </Tooltip>
          <Typography
            variant="p"
            color="text.secondary"
            textAlign="justify"
            gutterBottom
          >
            Allows only you to see what guests upload
          </Typography>
          <br />
          <Tooltip title="Check if you want to allow your guests to download files">
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  value={allowDownloads}
                  onChange={() => setAllowDownloads(!allowDownloads)}
                  name="allowDownloads"
                  id="allowDownloads"
                  defaultChecked={event?.allowDownloads}
                />
              }
              label="Allow Downloads"
            />
          </Tooltip>
          <Typography
            variant="p"
            color="text.secondary"
            textAlign="justify"
            gutterBottom
          >
            Allows all guests to download photos they posted if private, or all photos otherwise
          </Typography>
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, ...main_button }}
          >
            {!uploadingFile ? <>
              Edit Event
            </> : <>
              <CircularProgress />
            </>}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  token: state?.auth?.token,
  isLoading: state?.wait?.isLoading || state?.records?.isLoading,
  event: state?.records?.event,
});

const mapDispatchToProps = { getEvents, editEvent, getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
